@import url(https://fonts.googleapis.com/css2?family=Jura&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.flat-button{border:none;background:none;transition:all 300ms ease-in-out;padding:0;color:black}.flat-button:hover{color:black}.flat-button:hover:after{width:100%}.flat-button:after{display:block;content:"";width:0;height:1px;background:#fdab05;transition:all 300ms ease-in-out}

.drop-menu{position:fixed;top:0;left:0;width:100vw;height:100vh;background:rgba(255,255,255,0.4);-webkit-backdrop-filter:blur(5px);backdrop-filter:blur(5px);display:flex;flex-direction:column;justify-content:center;align-items:flex-end;transition:all 600ms ease-in-out;opacity:0;pointer-events:none}.drop-menu.open{opacity:1;pointer-events:all}.drop-menu .nav-item{opacity:0;transform:translateY(100%);transition:all 300ms ease-out;transition-delay:600ms}.drop-menu .nav-item.show-nav{opacity:1;transform:translateY(0)}.menu{display:flex;align-items:center;outline:none;width:100%;height:100%;z-index:2;cursor:pointer;transition:all 500ms ease-in-out;-webkit-transition:all 500ms ease-in-out}.menu.close .menu-lines div{transform:scale(0);-webkit-transform:scale(0)}.menu.close .menu-lines:before{transform:translate3d(0, 9px, 0) rotate(135deg);-webkit-transform:translate3d(0, 9px, 0) rotate(135deg)}.menu.close .menu-lines:after{transform:translate3d(0, -9px, 0) rotate(-135deg);-webkit-transform:translate3d(0, -9px, 0) rotate(-135deg)}.menu .menu-button{opacity:0;width:100%;height:100%;display:flex;justify-content:center;align-items:center;outline:none;-webkit-animation:fade-in 800ms ease-out forwards;animation:fade-in 800ms ease-out forwards;-webkit-animation-delay:1000ms;animation-delay:1000ms}.menu .menu-button:hover .menu-lines:before,.menu .menu-button:hover .menu-lines:after,.menu .menu-button:hover .menu-lines div{background:#111111}.menu .menu-button .menu-lines{position:relative;width:100%;padding:0;display:flex;justify-content:center;align-items:center}.menu .menu-button .menu-lines:before,.menu .menu-button .menu-lines:after,.menu .menu-button .menu-lines div{position:absolute;content:"";display:block;background:#111111;width:100%;height:2px;transition:all 500ms cubic-bezier(0.7, 0, 0.3, 1)}.menu .menu-button .menu-lines:before{left:0;top:-10px}.menu .menu-button .menu-lines:after{left:0;bottom:-10px}

.header{transition:all 300ms ease-out}.header.opaque{background:rgba(255,255,255,0.2);box-shadow:2px 2px 8px 0px rgba(0,0,0,0.2)}.header .logo{transition:opacity 300ms ease-out;opacity:0}.header .logo.show{opacity:1}.header .menu-label{opacity:0;-webkit-animation:fade-in 800ms ease-out forwards;animation:fade-in 800ms ease-out forwards;-webkit-animation-delay:1000ms;animation-delay:1000ms}

.sk-chase{width:100%;height:100%;position:relative;-webkit-animation:sk-chase 2.5s infinite linear both;animation:sk-chase 2.5s infinite linear both}.sk-chase-dot{width:100%;height:100%;position:absolute;left:0;top:0;-webkit-animation:sk-chase-dot 2.0s infinite ease-in-out both;animation:sk-chase-dot 2.0s infinite ease-in-out both}.sk-chase-dot:before{content:'';display:block;width:25%;height:25%;background-color:#000;border-radius:100%;-webkit-animation:sk-chase-dot-before 2.0s infinite ease-in-out both;animation:sk-chase-dot-before 2.0s infinite ease-in-out both}.sk-chase-dot:nth-child(1){-webkit-animation-delay:-1.1s;animation-delay:-1.1s}.sk-chase-dot:nth-child(2){-webkit-animation-delay:-1.0s;animation-delay:-1.0s}.sk-chase-dot:nth-child(3){-webkit-animation-delay:-0.9s;animation-delay:-0.9s}.sk-chase-dot:nth-child(4){-webkit-animation-delay:-0.8s;animation-delay:-0.8s}.sk-chase-dot:nth-child(5){-webkit-animation-delay:-0.7s;animation-delay:-0.7s}.sk-chase-dot:nth-child(6){-webkit-animation-delay:-0.6s;animation-delay:-0.6s}.sk-chase-dot:nth-child(1):before{-webkit-animation-delay:-1.1s;animation-delay:-1.1s}.sk-chase-dot:nth-child(2):before{-webkit-animation-delay:-1.0s;animation-delay:-1.0s}.sk-chase-dot:nth-child(3):before{-webkit-animation-delay:-0.9s;animation-delay:-0.9s}.sk-chase-dot:nth-child(4):before{-webkit-animation-delay:-0.8s;animation-delay:-0.8s}.sk-chase-dot:nth-child(5):before{-webkit-animation-delay:-0.7s;animation-delay:-0.7s}.sk-chase-dot:nth-child(6):before{-webkit-animation-delay:-0.6s;animation-delay:-0.6s}@-webkit-keyframes sk-chase{100%{transform:rotate(360deg)}}@keyframes sk-chase{100%{transform:rotate(360deg)}}@-webkit-keyframes sk-chase-dot{80%,100%{transform:rotate(360deg)}}@keyframes sk-chase-dot{80%,100%{transform:rotate(360deg)}}@-webkit-keyframes sk-chase-dot-before{50%{transform:scale(0.4)}100%,0%{transform:scale(1)}}@keyframes sk-chase-dot-before{50%{transform:scale(0.4)}100%,0%{transform:scale(1)}}

*{box-sizing:border-box}body{font-family:'Jura', sans-serif}.custom-yellow{color:#fdab05}.custom-yellow:visited{color:#fdab05}.bg-custom-yellow{background-color:#fdab05}.b--custom-yellow{border-color:#fdab05}.hover-bg-custom-yellow:focus,.hover-bg-custom-yellow:active,.hover-bg-custom-yellow:hover{background-color:#fdab05}.hover-custom-yellow:hover,.hover-custom-yellow:visited:hover{color:#fdab05}.custom-green{color:#94bd5e}.custom-green:visited{color:#94bd5e}.bg-custom-green{background-color:#94bd5e}.b--custom-green{border-color:#94bd5e}.hover-bg-custom-green:focus,.hover-bg-custom-green:active,.hover-bg-custom-green:hover{background-color:#94bd5e}.hover-custom-green:hover,.hover-custom-green:visited:hover{color:#94bd5e}.slanted{position:relative;z-index:1}.slanted:after,.slanted:before{content:'';position:absolute;width:100%;height:100%;background-color:inherit;transform:skew(-87deg);z-index:-1;left:0}.slanted:after{transform-origin:0 50%;top:50%}.slanted:before{top:-50%;transform-origin:0 50%}.diamond-top{position:relative;z-index:1}.diamond-top:after{content:'';position:absolute;width:0;height:0;z-index:-1;left:0;bottom:99.8%;border-left:50vw solid transparent;border-right:50vw solid transparent;border-bottom:10vw solid #CCCCCC}@-webkit-keyframes fade-in{0%{opacity:0}100%{opacity:1}}@keyframes fade-in{0%{opacity:0}100%{opacity:1}}

