@import url('https://fonts.googleapis.com/css2?family=Jura&display=swap');

* {
  box-sizing: border-box;
}

body {
  font-family: 'Jura', sans-serif;
}

$colours: (
  'custom-yellow': #fdab05,
  'custom-green': #94bd5e,
);

@each $name, $hex in $colours {
  .#{$name} {
    color: $hex;
    &:visited {
      color: $hex;
    }
  }
  .bg-#{$name} {
    background-color: $hex;
  }
  .b--#{$name} {
    border-color: $hex;
  }
  .hover-bg-#{$name} {
    &:focus,
    &:active,
    &:hover {
      background-color: $hex;
    }
  }
  .hover-#{$name}:hover,
  .hover-#{$name}:visited:hover {
    color: $hex
  }
}

.slanted {
  position: relative;
  z-index: 1;

  &:after,
  &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: inherit;
      transform: skew(-87deg);
      z-index: -1;
      left: 0;
  }

  &:after {
      transform-origin: 0 50%;
      top: 50%;
  }

  &:before {
      top: -50%;
      transform-origin: 0 50%;
  }
}

.diamond-top {
  position: relative;
  z-index: 1;

  &:after {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      z-index: -1;
      left: 0;
      bottom: 99.8%;
      border-left: 50vw solid transparent;
      border-right: 50vw solid transparent;
      border-bottom: 10vw solid #CCCCCC;
  }
}

@keyframes fade-in {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
}