.header {
    transition: all 300ms ease-out;
    
    &.opaque {
        // background: rgba($color: #555555, $alpha: 0.8);
        background: rgba($color: #ffffff, $alpha: 0.2);
        box-shadow: 2px 2px 8px 0px rgba( 0, 0, 0, 0.2 );
        // backdrop-filter: blur(2px);
    }

    .logo {
        transition: opacity 300ms ease-out;
        opacity: 0;
    
        &.show {
            opacity: 1;
        }
    }

    .menu-label {
        opacity: 0;
        animation: fade-in 800ms ease-out forwards;
        animation-delay: 1000ms;
    }
}