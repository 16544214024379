.flat-button {
    border: none;
    background: none;
    transition: all 300ms ease-in-out;
    padding: 0;
    color: black;

    &:hover {
        color: black;

        &:after {
            width: 100%;
        }
    }

    &:after {
        display: block;
        content: "";
        width: 0;
        height: 1px;
        background: #fdab05;
        transition: all 300ms ease-in-out;
    }
}
