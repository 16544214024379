.drop-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba($color: #fff, $alpha: 0.4);
  backdrop-filter: blur(5px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  transition: all 600ms ease-in-out;
  opacity: 0;
  pointer-events: none;

  &.open {
      opacity: 1;
      pointer-events: all;
  }

  .nav-item {
      opacity: 0;
      transform: translateY(100%);
      transition: all 300ms ease-out;
      transition-delay: 600ms;
      

      &.show-nav {
          opacity: 1;
          transform: translateY(0);
      }
  }
  
}

.menu {
  display: flex;
  align-items: center;
  outline: none;
  width: 100%;
  height: 100%;
  z-index: 2;
  cursor: pointer;
  transition: all 500ms ease-in-out;
  -webkit-transition: all 500ms ease-in-out;

  &.close {
    .menu-lines {
      div {
        transform: scale(0);
        -webkit-transform: scale(0);
      }

      &:before {
        transform: translate3d(0,9px,0) rotate(135deg);
        -webkit-transform: translate3d(0,9px,0) rotate(135deg);
      }

      &:after {
        transform: translate3d(0,-9px,0) rotate(-135deg);
        -webkit-transform: translate3d(0,-9px,0) rotate(-135deg);
      }
    }
  }

  .menu-button {
    opacity: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    animation: fade-in 800ms ease-out forwards;
    animation-delay: 1000ms;

    &:hover {
      .menu-lines {
        &:before,
        &:after,
        div {
          background: #111111;
        }
      }
    }

    .menu-lines {
      position: relative;
      width: 100%;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;

      &:before,
      &:after,
      div {
        position: absolute;
        content: "";
        display: block;
        background: #111111;
        width: 100%;
        height: 2px;
        transition: all 500ms cubic-bezier(0.7, 0, 0.3, 1);

        
      }
      
      &:before {
        left: 0;
        top: -10px;
      }

      &:after {
        left: 0;
        bottom: -10px;
      }
    }
  }
}